import React from "react"
import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Wave from "react-wavify"

import Hidden from "@material-ui/core/Hidden"

import SEO from "../components/seo"

function About() {
  return (
    <>
      <SEO title="Sobre | Grafiame" />
      <Header />
      <main>
        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                alt="Garoto segurando o icone Grafiame (G.)"
                className="me-img-content"
                src="/img/illustrations/withoutlegs.svg"
              ></img>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-left">
                A Grafiame<span className="me-the-end">.</span>
              </h2>
              <p>
                Somos uma empresa apaixonada pela Educação e, por isso,
                trabalhamos para atingir positivamente a Educação por meio de 3
                grandes frentes: tecnologia, escrita e ciência. E acreditamos
                estar no caminho certo ;)
              </p>
              <br />
              <p>
                [Depoimento de um parente] "Desde pequeninha, a Grafiame sempre
                teve o sonho de poder ajudar as pessoas com a Educação. E olha
                onde ela chegou!".
              </p>
            </Grid>
          </Grid>
        </Box>
        <Wave
          fill="#06d6a0"
          paused={false}
          options={{
            height: 2,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        <Box p={5} className="me-bg-verzul">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={7} sm={12}>
              <h2 className="me-h2">
                Capítulo 1<span className="me-the-end">.</span>
              </h2>
              <p>
                Nascemos há 10 anos desenvolvendo métodos e materiais voltados à
                leitura e à produção de texto com base na Neurolinguística para
                acelerar, facilitar e impulsionar a aprendizagem em comunicação
                que potencializa as habilidades e competências de cada um.
              </p>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                alt="Professora apontando para um quadro verde"
                className="me-img-content"
                src="/img/illustrations/singingtheabc.svg"
              ></img>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Hidden smDown>
              <Grid
                item
                container
                md={5}
                sm={12}
                justify="center"
                alignItems="center"
              >
                <img
                  alt="Menino em uma bicicleta."
                  className="me-img-content"
                  src="/img/illustrations/takingmytimeonmyride.svg"
                ></img>
              </Grid>
            </Hidden>
            <Grid item md={7} sm={12}>
              <h2 className="me-h2">
                Capítulo 2<span className="me-the-end">.</span>
              </h2>
              <p>
                Essa nova fase da Grafiame representa o que nós nos tornamos:
                solucionadores de questões pontuais de comunicação, de leitura e
                de produção de texto por meio da tecnologia produzida aqui no
                Brasil.
              </p>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={7} sm={12}>
              <h2 className="me-h2">
                Capítulo 3<span className="me-the-end">.</span>
              </h2>
              <p>
                Implantamos em escolas e empresas o Núcleo de Redação e de
                Linguagem para que a nossa paixão pela Educação envolva todos os
                participantes e dê ótimos resultados.
              </p>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <img
                alt="Uma menina segurando uma xícara de café."
                className="me-img-content"
                src="/img/illustrations/thegirlandthecoffee.svg"
              ></img>
            </Grid>
          </Grid>
        </Box>

        <Wave
          className="me-inverse"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 4,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />

        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-center">
                Venha trabalhar com a gente<span className="me-the-end">!</span>
              </h2>
              <Grid container justify="center">
                <img
                  alt="Aluno sendo ajuda por uma professora a subir uma escada, apoiada em um celular"
                  className="me-img-content"
                  src="/img/illustrations/thisisahugephone.svg"
                ></img>
              </Grid>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item container md={5} sm={12} justify="center">
              <Box p={5} boxShadow={3} borderRadius={10}>
                <p>
                  Estamos sempre em busca de novos talentos para somar na
                  Grafiame! Seja em nossa equipe pedagógica, tecnológica,
                  administrativa ou jurídica. Certamente, há algum lugar para
                  você por aqui!{" "}
                </p>
                <br />
                <p>
                  Então, que tal revolucionarmos a educação, juntos? Mande sua
                  cartinha, digo, mande um email para{" "}
                  <b>vagas@grafiame.com.br</b> para conversarmos melhor!
                </p>
              </Box>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
      </main>
      <Footer />
    </>
  )
}

export default About
